<template>
  <div class="layout">
    <navbar></navbar>
      <router-view></router-view>
    <div class="copyright">
      <copyright />
    </div>
  </div>
</template>

<script>
import navbar from '@/components/Navbar.vue'
import copyright from '@/components/Copyright.vue'
export default {
  components: {
    navbar,
    copyright
  },
  created(){
  }
}
</script>

<style scoped lang="scss">
.layout {
  background-color: #f3f3f3;
  .copyright {
    margin-top: 32px;
    background-color: black;
  }
}
</style>
